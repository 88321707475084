/* tableStyle */
.tableStyle {
    position: relative;
    height: auto;
    width: 100%;
    background-color: #DDD3A0;
    border-radius: 4px;
    padding: 0px;
    margin: 0px;
    overflow: auto;
    border: 1px dotted black;
    box-sizing: border-box;
  }
  
  /* filterTableStyle */
  .filterTableStyle {
    position: relative;
    height: auto;
    width: 98%;
    background-color: #DDDDDD;
    border-radius: 4px;
    padding: 12px 8px;
    margin: 12px 0px 24px 0px;
    overflow: auto;
    border: 1px dotted black;
  }
  
  /* modalStyle */
  .modalStyle {
    position: absolute;
    display: grid;
    overflow: hidden;
    background-color: white;
    opacity: 95%;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding: 12px 12px;
    box-sizing: border-box;
  }
  
  /* modalRestyle */
  .modalRestyle-overlay {
    width: 40%;
    height: 44%;
    top: 54%;
    left: 1%;
    overflow: hidden;
    border-radius: 15px;
    border: 4px solid black;
    box-sizing: border-box;
    padding: 0;
  }
  
  .modalRestyle-content {
    overflow: hidden;
    background: none;
    border: none;
    inset: 0px;
    padding: 0;
  }
  
  /* noteStyle */
  .noteStyle {
    border: 2px solid #526666;
    resize: none;
    font-size: 20px;
    padding: 4px 4px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  /* notInAnyZone */
  .notInAnyZone {
    font-size: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  
  /* endOfDocTable */
  .endOfDocTable {
    position: relative;
    height: auto;
    width: 100%;
    background-color: #e7d4d8;
    border-radius: 4px;
    margin: 0;
    padding: 12px 8px;
    overflow: auto;
    border: 1px dotted black;
  }
  
  /* pdfCopy */
  .pdfCopy {
    display: flex;
    flex-direction: column;
    margin: 128px 12px 12px 12px;
    padding: 16px;
    font-size: 28px;
    width: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  /* theadStyle */
  .theadStyle {
    font-size: 16px;
    margin: 0;
  }
  
  /* newHeaderStyle */
  .newHeaderStyle {
    font-size: 32px;
    padding-bottom: 12px;
  }
  
  /* borderTop */
  .borderTop {
    position: relative;
    grid-template-rows: auto auto;
    height: auto;
    width: 100%;
    border-radius: 4px;
    border-top: 0px solid black;
    margin-top: 0;
  }
  
  /* getZonePairingsContainer */
  .getZonePairingsContainer {
    display: block;
  }
  
  /* noMatchTableStyle */
  .noMatchTableStyle {
    position: relative;
    height: auto;
    width: 100%;
    background-color: #e7d4d8;
    border-radius: 4px;
    padding: 12px 0px;
    margin: 10px 0px 0px 0px;
    overflow-x: hidden;
    border: 1px dotted black;
  }
  
  /* lastRow */
  .lastRow {
    display: flex;
    justify-content: space-between;
    grid-template-columns: auto;
    width: 100%;
    align-items: center;
    padding: 4px 4px;
  }
  
  /* filterStyle */
  .filterStyle {
    height: 16px;
    padding: 6px;
  }
  
  /* centeredHeader */
  .centeredHeader {
    text-align: center;
  }
  
  /* blockStyle */
  .blockStyle {
    display: block;
  }
  
  /* mapStyle */
  .mapStyle {
    position: relative;
    height: auto;
    display: grid;
    grid-template-rows: auto auto;
    margin-top: 6px;
  }
  
  /* verticalLabel */
  .verticalLabel {
    padding: 16px 4px;
    margin: 4px 4px;
    text-align: center;
    border: none;
    font-style: italic;
    opacity: 50%;
    text-decoration: underline;
  }
  
  /* labelStyle */
  .labelStyle {
    margin: 12px 0px;
    color: #00B3B5;
    text-align: left;
    font-size: 14px;
  }
  
  /* doc2ZoneStyle */
  .doc2ZoneStyle {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #00B3B5;
    text-decoration: underline;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }
  
  /* updateNoteButton */
  .updateNoteButton {
    background-color: #723E49;
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 0 8px;
    display: inline;
    width: 20%;
  }
  
  /* zoneStyle */
  .zoneStyle {
    margin: 12px 0px;
    font-weight: bold;
    font-size: 13px;
    padding: 2px 12px;
    color: #526666;
  }
  
  /* thStyle */
  .thStyle {
    font-weight: bold;
    font-style: italic;
    color: #526666;
    border: none;
    text-align: left;
    align-self: center;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  
  /* trStyle */
  .trStyle {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  
  /* exportButtons */
  .exportButtons {
    display: flex;
    position: fixed;
    margin: 0;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: flex-end;
    width: 85%;
    z-index: 1000;
    top: 9%;
    left: 15%;
  }
  
  /* trStyleFlexStart */
  .trStyleFlexStart {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }
  
  /* tbodyStyle */
  .tbodyStyle {
    overflow: auto;
    display: inline;
  }
  
  /* tbodyNote */
  .tbodyNote {
    overflow: auto;
    display: inline;
    margin-top: 24px;
    inline-size: 80%;
    word-break: break-all;
    font-weight: bold;
    font-size: 18px;
  }
  
  /* tdPropStyle */
  .tdPropStyle {
    display: block;
    margin: 0;
    color: #00B3B5;
    text-align: left;
    font-size: 13px;
  }
  
  /* tdValueStyle */
  .tdValueStyle {
    color: #526666;
    font-size: 13px;
  }
  
  /* containsPropVal */
  .containsPropVal {
    display: block;
    width: 200px;
    overflow: auto;
    padding: 0 4px;
    margin: 12px 0px 0px 0px;
  }
  

.green {
    color: green;
    font-weight: 900;
}
.red {
    color: red;
    font-weight: 900;
}
.hamburger-button {
    position: fixed;
    top: 10%;
    right: 2%;
    border: none;
    background: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 24px;
    transform: rotate(90deg); /* Rotates the button */
}

.comparison-results-actions-div {
    z-index: 9999;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    margin: -16px 0 0 0;
    right: 0%;
    background: #00B3B5; /* Updated background */
    color: white; /* White text */
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0;
    list-style: none;
    min-width: 200px;
    z-index: 9999; /* High z-index to keep it on top */
    overflow: hidden;
}

.dropdown-menu ul {
    margin: 0;
    padding: 0;
}

.dropdown-menu li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    color: white; /* Ensures text is white */
    border-bottom: 1px solid white; /* White border between buttons */
}

.dropdown-menu li:last-child {
    border-bottom: none; /* Remove border from last item */
}

.dropdown-menu li:hover {
    background: #008f91; /* Slightly darker shade on hover */
}

.dropdown-menu input[type="range"] {
    width: 100%;
    margin: 10px 0;
}

.filter-label {
    font-size: 14px;
    display: block;
    text-align: center;
    margin-top: 4px;
    color: white; /* White text for label */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .comparison-results-container {
        position: fixed;
        top: 15%;
        bottom: 2%;
        width: 95%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
            
    .comparison-results-container {
        position: fixed;
        top: 15%;
        width: 96%;
        bottom: 2%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: white;
        border-radius: 24px;
        overflow-x: scroll;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
   
    .comparison-results-container {
        position: fixed;
        top: 15%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: auto;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .comparison-results-container {
        position: fixed;
        top: 10%;
        width: 97%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: white;
        border-radius: 24px;
        overflow-x: auto;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .comparison-results-container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        top: 10%;
        width: 83%;
        bottom: 2%;
        box-sizing: border-box;
        margin-left: 16px;
        margin-right: 16px;
        padding: 16px 0px;
        background-color: white;
        border-radius: 24px;
    }

}

.h1-comparison-results {
    position: fixed;
    left: 17%;
    top: 9%;
    padding: 16px 16px;
    font-size: 1.5em;
    color: #555555;
}

.zone-name {
    color: #00B3B5;
    font-weight: bold;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    width: auto;
}

.table-zones {
    width: auto;
}

.table-zones span {
    color: #555555;
}

.table-zones tr {
    overflow: auto;
}

.filter-container {
    display: inline;
    background-color: white;
    position: fixed;
    top: 15%;
    width: 81%;
    align-items: center;
    z-index: 10;
    border-radius: 8px;
    padding: 12px 0px;
}

#filter-percentage {
    background-color: #723e49;
    float: right;
    padding: 4px 0px;
}

.match-percentage-data {
    font-weight: bold;
    font-style: italic;
    color: #00B3B5;
}

.match-percentage {
    font-weight: bold;
    font-style: italic;
    color: #00B3B5;
}

.match-percentage strong {
    font-weight: bold;
    font-style: italic;
    color: #723e49;
}

.match-percentage-data-strong {
    font-weight: bold;
    font-style: italic;
    color: #723e49;
}

.pairings-container {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 16px;
    padding: 16px 16px;
    overflow-y: auto;
    overflow-x: auto;
    position: fixed;
    top: 12%;
    width: 80%;
    height: 84%;
    border-radius: 24px;
}

.line-item-table {
    position: relative;
    margin: 12px 6px;
    box-sizing: border-box;
    padding: 24px 6px;
    height: 100px;
    width: auto;
    background-color: #DDDDDD;
    border-collapse: collapse;
    white-space: nowrap;
    overflow-x: auto;
}


.line-item-table tr:first-child() td:first-child(){
    overflow-x: auto;
}

.line-item-table tr:nth-child(2) td:first-child{
    overflow-x: auto;
}

.line-item-table tr:last-child() td{
    overflow-x: hidden;
}

.on-load-message-container {
    padding: 12px 6px;
    position: fixed;
    display: flex;
    justify-content: center;
    top: 30%;
    align-items: center;
    width: inherit;
}

.loading-comparisons-text {
    font-weight: bold;
    color: #1976D2;
    font-size: 1.5em;
}

.toggle-results-button {
    border: none;
    background-color: #00B3B5;
    color: white;
    font-size: 0.67em;
    text-align: center;
    font-weight: bold;
    float: left;
    height: 28px;
    width: 100px;
    margin: 0px 0px 0px 8px;
}

.add-dropdown-button {
    background-color: #00B3B5;
    margin: 4px 0px;
    border: none;
    color: white;
    padding: 0px 8px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 1.5em;
    border-radius: 30%;
}

.dropdown-style {
    text-align: center;
    margin: 8px 12px;
    font-weight: bold;
    font-size: 1em;
    padding: 1px 0px 0px 0px;
    color: #555555;
    border: 1px dotted #555555;
    border-radius: 4px;
    background-color: #DDDDDD;
    width: 50px
}

.comparison-results-actions-div {
    width: 80%;
    padding: 8px 8px;
    position: fixed; 
    display: flex;
    align-items: center; 
    justify-content: flex-end; 
    top: 15%;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000; /* High z-index to appear above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  z-index: 1001; /* Ensure content appears above overlay */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

#pdfCopyPreview {
  padding: 10px;
}

/*
table:first-child() {
    height: 40%;
}

table:nth-child(1) {
    height: 40%;
}

table:last-child() {
    height: 40%;
}
*/
.differentials, .differential strong {
    font-size: 13px;
    margin: '0px 0px 0px 0px';
    padding: '0px 0px 0px 0px';
}

.right-container{
padding: 0px 0px 0px 0px;   
margin: 0px 0px 0px 0px;
}
.comparison-results-actions-div {
    padding: 0px 0px 0px 0px;   
    margin: 0px 0px 0px 0px;
}